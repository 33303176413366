import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home/index";
import { Thanks } from "./thanks";
import TripID from "./trip/[id]";
import PayorVerification from "./trip/[id]/payor-verification";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/trip/:tripId" element={<TripID />} />
          <Route
            path="/trip/:tripId/payor-verification"
            element={<PayorVerification />}
          />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
