import { Container } from "../components/Container";
import { CheckCircleIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { Button } from "../components/Button";
import Moment from "react-moment";
import { Triangle } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export function TripConfirmation(props) {
  const params = useParams();
  let navigate = useNavigate();
  const id = params.tripId;
  function confirmationTrip(b) {
    let payload = {
      assignId: id,
    };

    if (b) {
      payload.isConfirmedTrip = true;
    } else {
      payload.isMarkedCancelledTrip = true;
    }

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(`https://nemtpanel.com/api/assigns/tripconfirmation`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          toast.error(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        if (payload.isConfirmedTrip == true) {
          toast.success("Confirmed Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (payload.isMarkedCancelledTrip == true) {
          toast.warn("Cancelled Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        props.setTripDetails(data);
        if (
          "6192ac833550ee320f1195af" === data.company &&
          payload.isConfirmedTrip === true
        ) {
          navigate(`/trip/${data._id}/payor-verification`);
        }
      });
  }

  return (
    <>
      {props.tripDetails ? (
        <section>
          <Container>
            <div>
              <div className="h-auto pt-4 px-4 bg-blue-50 pb-10 text-xl font-bold text-gray-900">
                Hello{" "}
                <span className="text-is-secondary">
                  {" "}
                  {props.tripDetails.priorityClient
                    ? props.tripDetails.priorityClient.displayName
                    : "Not Valid Name"}
                </span>
                , Please confirm your ride for{" "}
                <span className="text-is-secondary">
                  {" "}
                  {props.tripDetails.scheduleTime ? (
                    <Moment format="ddd D MMM YYYY">
                      {props.tripDetails.scheduleTime}
                    </Moment>
                  ) : (
                    "Not Valid Date"
                  )}
                </span>
              </div>
              <div className="p-4 relative -mt-8">
                <div className="bg-white shadow h-full p-4 rounded-lg space-y-8">
                  <div className="relative">
                    <ul>
                      <li className="relative pb-3">
                        <div className="absolute inset-0 border-l-4 border-indigo-600 border-dotted h-full ml-3"></div>
                        <div className="flex items-start space-x-4 relative">
                          <div className="h-7 w-7 grid place-content-center bg-white">
                            <div className="h-4 w-4 rounded ring-2 ring-indigo-600 p-0.5">
                              <div className="bg-indigo-600 rounded h-full w-full"></div>
                            </div>
                          </div>
                          <div className="flex flex-col flex-1 mt-1">
                            <div className="text-sm text-gray-900 font-medium">
                              {props.tripDetails.jobOriginAddress
                                ? props.tripDetails.jobOriginAddress
                                : "Not Valid Address"}
                            </div>
                            <div className="text-sm text-gray-500">
                              {props.tripDetails.scheduleTime ? (
                                <Moment format="hh:mm A">
                                  {props.tripDetails.scheduleTime}
                                </Moment>
                              ) : (
                                "Not Valid Time"
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="relative">
                        <div className="flex items-start space-x-4 relative">
                          <div className="h-7 w-7 grid place-content-center bg-white">
                            <div className="h-4 w-4 rounded ring-2 ring-indigo-600 p-0.5">
                              <div className="bg-indigo-600 rounded h-full w-full"></div>
                            </div>
                          </div>
                          <div className="flex flex-col flex-1 mt-1">
                            <div className="text-sm text-gray-900 font-medium">
                              {props.tripDetails.jobDestinationAddress
                                ? props.tripDetails.jobDestinationAddress
                                : "Not Valid Address"}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {props.tripDetails.isConfirmedTrip ||
                  props.tripDetails.isMarkedCancelledTrip ? (
                    <div className="rounded-md bg-green-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-green-800">
                            Already Submitted
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col space-y-2">
                      <h2 className="text-lg font-semibold text-gray-900">
                        Please confirm or cancel your trip
                      </h2>
                      <div className="grid grid-cols-2 gap-4">
                        <Button onClick={() => confirmationTrip(true)}>
                          Confirm ride
                        </Button>
                        <Button
                          onClick={() => confirmationTrip(false)}
                          variant="outline"
                        >
                          Cancel ride
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </section>
      ) : (
        <div className="fixed inset-0 grid place-content-center bg-white">
          <Triangle
            height="100"
            width="100"
            color="#fca311"
            ariaLabel="loading"
          />
        </div>
      )}
      <ToastContainer />
    </>
  );
}
