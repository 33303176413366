import React from "react";
import { Container } from "../components/Container";
import { CheckCircleIcon, LockClosedIcon } from "@heroicons/react/24/solid";

export function Thanks() {
  return (
    <>
      <section>
        <Container>
          <div className="min-h-screen grid place-content-center">
            <div className="flex flex-col w-full items-center text-center">
              <div className="h-16 w-16 rounded-full overflow-hidden grid place-content-center bg-blue-50 p-2 shadow">
                <CheckCircleIcon className="h-6 w-6 text-blue-500" />
              </div>
              <h1 className="text-xl font-bold text-gray-900 max-w-xs mt-6">
                Thanks for the confirmation
              </h1>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
