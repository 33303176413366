import { Camera } from "../../components/Camera";
import React from "react";

export default function payorVerification() {
  return (
    <>
      <Camera />
    </>
  );
}
