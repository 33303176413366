import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MemberConfirmation } from "../../components/MemberConfirmation";
import { TripConfirmation } from "../../components/TripConfirmation";
import { useParams } from "react-router-dom";

export default function TripID() {
  const params = useParams();
  let navigate = useNavigate();
  const id = params.tripId;
  const [tripDetails, setTripDetails] = useState(null);
  const [validate, setValidate] = useState(false);
  console.log("tripDetails", tripDetails);
  console.log(validate);

  useEffect(() => {
    if (!id) return;
    fetch(`https://nemtpanel.com/api/assigns/tripconfirmation?assignId=${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTripDetails(data);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, [id]);

  return (
    <div>
      {!validate && (
        <MemberConfirmation
          validate={validate}
          setValidate={setValidate}
          tripDetails={tripDetails}
        />
      )}
      {validate && (
        <TripConfirmation
          tripDetails={tripDetails}
          setTripDetails={setTripDetails}
        />
      )}

      <ToastContainer />
    </div>
  );
}
