import { Container } from "../components/Container";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Button } from "../components/Button";
import { Triangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

export function MemberConfirmation(props) {
  let navigate = useNavigate();

  //   console.log("props", props);

  function ConfirmationValidation(action) {
    if (!action) {
      console.log("action", "move to other page");
      navigate("/thanks");
    }
    props.setValidate(action);
  }

  return (
    <>
      {props.tripDetails ? (
        <section>
          <Container>
            <div className="min-h-screen grid place-content-center">
              <div className="flex flex-col w-full items-center text-center">
                <div className="h-16 w-16 rounded-full overflow-hidden grid place-content-center bg-blue-50 p-2 shadow">
                  <LockClosedIcon className="h-6 w-6 text-blue-500" />
                </div>
                <h1 className="text-xl font-bold text-gray-900 max-w-md mt-6">
                  Hi! We are your transportation provider. Please confirm the
                  below name is yours?
                </h1>
                <p className="text-gray-500 text-2xl max-w-md mt-6 bg-blue-50 px-4 py-2 rounded">
                  <span className="text-is-secondary font-bold">
                    {props.tripDetails.priorityClient
                      ? props.tripDetails.priorityClient.displayName
                      : "Not Valid Name"}
                  </span>{" "}
                </p>
                <div className="w-full flex items-center justify-center gap-4 mt-8">
                  <Button onClick={() => ConfirmationValidation(true)}>
                    Yes, It&apos;s Me
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => ConfirmationValidation(false)}
                  >
                    Not Me
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </section>
      ) : (
        <div className="fixed inset-0 grid place-content-center bg-white">
          <Triangle
            height="100"
            width="100"
            color="#fca311"
            ariaLabel="loading"
          />
        </div>
      )}
    </>
  );
}
